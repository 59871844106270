import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import axios from "axios";

const AppointmentTabContent = (props) => {
	const months = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık']
	const days = ['Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct', 'Pz']
	const locale = {
		localize: {
			month: n => months[n],
			day: n => days[n]
		},
		formatLong: {}
	}
	const [startDate, setStartDate] = useState(new Date());
	const [personel, setPersonel] = useState(props.defaultTeammate);
	const [service, setService] = useState(props.defaultService);
	const [saat, setSaat] = useState('0');
	const [not, setNot] = useState('')
	const [phone, setPhone] = useState('0')
	const [kayitsizCustomerFullName, setKayitsizCustomerFullName] = useState('')
	const [disableButton, setDisableButton] = useState(0)
	const [appointmentsArrayForHours, setAppointmentArrayForHours] = useState([])
	const onChangePersonel = (selectedPersonel) => {
		setPersonel(selectedPersonel.target.value)
		setSaat('0')
	}
	const onChangeHizmet = (selectedHizmet) => {
		setService(selectedHizmet.target.value)
		setSaat('0')
	}
	const formatDate = (date) => {
		var d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2)
			month = '0' + month;
		if (day.length < 2)
			day = '0' + day;

		return [year, month, day].join('-');
	}
	function dateDiffInHours(a, b) {
		let diff = Math.abs(a - b);
		return Math.floor((diff / 1000) / 60);
	}
	useEffect(() => {
		appointmentCalendarPrepare(startDate);
	}, [props.appointments])
	const appointmentCalendarPrepare = (date) => {
		console.log('appCalPrepare Başladı', date)
		let appointmentsArray = [];
		props.appointments.forEach((item) => {
			if(formatDate(date) === formatDate(new Date(item.appointmentTime))){
				let difference = dateDiffInHours(new Date(item.appointmentEndTime), new Date(item.appointmentTime));
				let addObj = {
					id: item.id,
					startDate: item.appointmentTime,
					endDate: item.appointmentEndTime,
					insideDates: [],
					teammateID: item.teammateID,
					serviceID: item.service_id
				}
				let eklenecekMiktarAdedi = difference / 15;
				for(let i = 0; i<eklenecekMiktarAdedi; i++){
					let newDateObj = new Date();
					newDateObj.setTime(new Date(item.appointmentTime).getTime() + (i * 15 * 60 * 1000))
					addObj.insideDates.push(newDateObj)
				}
				appointmentsArray.push(addObj);
			}
		})
		setAppointmentArrayForHours(appointmentsArray)
	}
	const returnDisabledHour = (hour, minute, date) => {
		if(new Date().getMonth() === new Date(date).getMonth() && new Date().getDate() === new Date(date).getDate()){
			// demek ki bu gün tarihindeyiz önceki saatleri toparla
			if(hour <= new Date().getHours()){
				return true;
			}
			else{
				// if(props.appointments.find(item =>{
				// 				if (new Date(item.appointmentTime).getDate() === startDate.getDate()){
				// 					if(personel === ''){
				// 						if (minute === 30) {
				// 							return (new Date(item.appointmentTime).getHours() === hour && new Date(new Date(item.appointmentTime).getTime() + 15 * 60000).getMinutes() === minute) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id;
				// 						}
				// 						else {
				// 							return (new Date(item.appointmentTime).getHours() === hour - 1 && new Date(item.appointmentTime).getMinutes() === minute + 45) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id;
				// 						}
				// 					}
				// 					else{
				// 						if (minute === 30) {
				// 							return (new Date(item.appointmentTime).getHours() === hour && new Date(new Date(item.appointmentTime).getTime() + 15 * 60000).getMinutes() === minute) && item.teammateID === parseInt(personel);
				// 						}
				// 						else {
				// 							return (new Date(item.appointmentTime).getHours() === hour - 1 && new Date(item.appointmentTime).getMinutes() === minute + 45) && item.teammateID === parseInt(personel);
				// 						}
				// 					}
				// 				}
				// 			}) !== undefined && props.appointments.find(item => {
				// 			if (new Date(item.appointmentTime).getDate() === startDate.getDate()) {
				// 				if(personel === '') {
				// 					if (minute === 30) {
				// 						return (new Date(item.appointmentEndTime).getHours() === hour && new Date(new Date(item.appointmentEndTime).getTime() - 15 * 60000).getMinutes() === minute) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id;
				// 					}
				// 					else {
				// 						return (new Date(item.appointmentEndTime).getHours() === hour && new Date(item.appointmentEndTime).getMinutes() === minute + 15) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id;
				// 					}
				// 				}
				// 				else{
				// 					if (minute === 30) {
				// 						return (new Date(item.appointmentEndTime).getHours() === hour && new Date(new Date(item.appointmentEndTime).getTime() - 15 * 60000).getMinutes() === minute) && item.teammateID === parseInt(personel);
				// 					}
				// 					else {
				// 						return (new Date(item.appointmentEndTime).getHours() === hour && new Date(item.appointmentEndTime).getMinutes() === minute + 15) && item.teammateID === parseInt(personel);
				// 					}
				// 				}
				// 			}
				// 		}) !== undefined){
				// 	//burada sıkıntı yok çalışıyor elleme
				// 	return (
				// 		props.appointments
				// 			.find(item =>{
				// 					if (new Date(item.appointmentTime).getDate() === startDate.getDate()){
				// 						if(personel === ''){
				// 							if (minute === 30) {
				// 								return (new Date(item.appointmentTime).getHours() === hour && new Date(new Date(item.appointmentTime).getTime() + 15 * 60000).getMinutes() === minute) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id;
				// 							}
				// 							else {
				// 								return (new Date(item.appointmentTime).getHours() === hour - 1 && new Date(item.appointmentTime).getMinutes() === minute + 45) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id;
				// 							}
				// 						}
				// 						else{
				// 							if (minute === 30) {
				// 								return (new Date(item.appointmentTime).getHours() === hour && new Date(new Date(item.appointmentTime).getTime() + 15 * 60000).getMinutes() === minute) && item.teammateID === parseInt(personel);
				// 							}
				// 							else {
				// 								return (new Date(item.appointmentTime).getHours() === hour - 1 && new Date(item.appointmentTime).getMinutes() === minute + 45) && item.teammateID === parseInt(personel);
				// 							}
				// 						}
				// 					}
				// 				}
				// 			) !== undefined
				// 		&&
				// 		props.appointments
				// 			.find(item => {
				// 				if (new Date(item.appointmentTime).getDate() === startDate.getDate()) {
				// 					if(personel === '') {
				// 						if (minute === 30) {
				// 							return (new Date(item.appointmentEndTime).getHours() === hour && new Date(new Date(item.appointmentEndTime).getTime() - 15 * 60000).getMinutes() === minute) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id;
				// 						}
				// 						else {
				// 							return (new Date(item.appointmentEndTime).getHours() === hour && new Date(item.appointmentEndTime).getMinutes() === minute + 15) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id;
				// 						}
				// 					}
				// 					else{
				// 						if (minute === 30) {
				// 							return (new Date(item.appointmentEndTime).getHours() === hour && new Date(new Date(item.appointmentEndTime).getTime() - 15 * 60000).getMinutes() === minute) && item.teammateID === parseInt(personel);
				// 						}
				// 						else {
				// 							return (new Date(item.appointmentEndTime).getHours() === hour && new Date(item.appointmentEndTime).getMinutes() === minute + 15) && item.teammateID === parseInt(personel);
				// 						}
				// 					}
				// 				}
				// 			}) !== undefined
				// 	)
				// }
				// if(props.appointments.find(item =>{if (new Date(item.appointmentTime).getDate() === startDate.getDate()){
				// 	if(personel === '') {
				// 		return (new Date(item.appointmentTime).getHours() === hour && new Date(item.appointmentTime).getMinutes() === minute) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id
				// 			||
				// 			(new Date(item.appointmentEndTime).getHours() === hour && new Date(item.appointmentEndTime).getMinutes() === minute) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id
				// 	}
				// 	else{
				// 		return (new Date(item.appointmentTime).getHours() === hour && new Date(item.appointmentTime).getMinutes() === minute) && item.teammateID === parseInt(personel)
				// 			||
				// 			(new Date(item.appointmentEndTime).getHours() === hour && new Date(item.appointmentEndTime).getMinutes() === minute) && item.teammateID === parseInt(personel);
				// 	}
				// }}) !== undefined){
				// 	//burada sıkıntı yok çalışıyor elleme
				// 	return props.appointments
				// 		.find(item =>{
				// 				if (new Date(item.appointmentTime).getDate() === startDate.getDate()){
				// 					if(personel === '') {
				// 						return (new Date(item.appointmentTime).getHours() === hour && new Date(item.appointmentTime).getMinutes() === minute) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id
				// 							||
				// 							(new Date(item.appointmentEndTime).getHours() === hour && new Date(item.appointmentEndTime).getMinutes() === minute) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id
				// 					}
				// 					else{
				// 						return (new Date(item.appointmentTime).getHours() === hour && new Date(item.appointmentTime).getMinutes() === minute) && item.teammateID === parseInt(personel)
				// 							||
				// 							(new Date(item.appointmentEndTime).getHours() === hour && new Date(item.appointmentEndTime).getMinutes() === minute) && item.teammateID === parseInt(personel);
				// 					}
				// 				}
				// 			}
				// 		) !== undefined;
				// }
				// if(minute === 15 || minute === 45){
				// 	//burada sıkıntı yok çalışıyor elleme
				// 	return (props.appointments
				// 		.find(item =>{
				// 			if (new Date(item.appointmentTime).getDate() === startDate.getDate()){
				// 				if(personel === ''){
				// 					return new Date(item.appointmentTime).getHours() === hour && new Date(item.appointmentTime).getMinutes() === minute - 15 && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id; 
				// 				}
				// 				else{
				// 					return new Date(item.appointmentTime).getHours() === hour && new Date(item.appointmentTime).getMinutes() === minute - 15 && item.teammateID === parseInt(personel);
				// 				}
				// 			}
				// 		}
				// 		) !== undefined && props.appointments
				// 		.find(item => {
				// 			if (new Date(item.appointmentTime).getDate() === startDate.getDate()) {
				// 				if(personel === '') {
				// 					if (minute === 45) {
				// 						return (new Date(item.appointmentEndTime).getHours() === hour + 1 && new Date(new Date(item.appointmentEndTime).getTime() - 15 * 60000).getMinutes() === minute) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id;
				// 					} else {
				// 						return (new Date(item.appointmentEndTime).getHours() === hour && new Date(item.appointmentEndTime).getMinutes() === minute + 15) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id;
				// 					}
				// 				}
				// 				else{
				// 					if (minute === 45) {
				// 						return (new Date(item.appointmentEndTime).getHours() === hour + 1 && new Date(new Date(item.appointmentEndTime).getTime() - 15 * 60000).getMinutes() === minute) && item.teammateID === parseInt(personel);
				// 					} else {
				// 						return (new Date(item.appointmentEndTime).getHours() === hour && new Date(item.appointmentEndTime).getMinutes() === minute + 15) && item.teammateID === parseInt(personel);
				// 					}
				// 				}
				// 			}
				// 		}) !== undefined)
				// }
				//burada sıkıntı yok çalışıyor elleme
				// return props.appointments.find(item =>{if (new Date(item.appointmentTime).getDate() === startDate.getDate()){
				// 			if(personel === '') {
				// 				return (new Date(item.appointmentTime).getHours() === hour && new Date(item.appointmentTime).getMinutes() === minute) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id
				// 					||
				// 					(new Date(item.appointmentEndTime).getHours() === hour && new Date(item.appointmentEndTime).getMinutes() === minute) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id
				// 			}
				// 			else{
				// 				return (new Date(item.appointmentTime).getHours() === hour && new Date(item.appointmentTime).getMinutes() === minute) && item.teammateID === parseInt(personel)
				// 					||
				// 					(new Date(item.appointmentEndTime).getHours() === hour && new Date(item.appointmentEndTime).getMinutes() === minute) && item.teammateID === parseInt(personel);
				// 			}
				// 		}}) !== undefined;
				
				return appointmentsArrayForHours.find(item =>{
					if (new Date(item.startDate).getDate() === startDate.getDate()){
						if(personel === '') {
							if(item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id){
								return item.insideDates.find((insideDate) => {
									return new Date(insideDate).getHours() === hour && new Date(insideDate).getMinutes() === minute
								}) !== undefined
							}
						}
						else{
							if(item.teammateID === parseInt(personel)){
								return item.insideDates.find((insideDate) => {
									return new Date(insideDate).getHours() === hour && new Date(insideDate).getMinutes() === minute
								}) !== undefined
							}
						}
					}}) !== undefined
			}
		}
		else{
			//demek ki ileri tarihteyiz normal returnle
			// if(props.appointments.find(item =>{
			// 	if (new Date(item.appointmentTime).getDate() === startDate.getDate()){
			// 		if(personel === ''){
			// 			if (minute === 30) {
			// 				return (new Date(item.appointmentTime).getHours() === hour && new Date(new Date(item.appointmentTime).getTime() + 15 * 60000).getMinutes() === minute) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id;
			// 			}
			// 			else {
			// 				return (new Date(item.appointmentTime).getHours() === hour - 1 && new Date(item.appointmentTime).getMinutes() === minute + 45) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id;
			// 			}
			// 		}
			// 		else{
			// 			if (minute === 30) {
			// 				return (new Date(item.appointmentTime).getHours() === hour && new Date(new Date(item.appointmentTime).getTime() + 15 * 60000).getMinutes() === minute) && item.teammateID === parseInt(personel);
			// 			}
			// 			else {
			// 				return (new Date(item.appointmentTime).getHours() === hour - 1 && new Date(item.appointmentTime).getMinutes() === minute + 45) && item.teammateID === parseInt(personel);
			// 			}
			// 		}
			// 	}
			// }) !== undefined && props.appointments.find(item => {
			// 	if (new Date(item.appointmentTime).getDate() === startDate.getDate()) {
			// 		if(personel === '') {
			// 			if (minute === 30) {
			// 				return (new Date(item.appointmentEndTime).getHours() === hour && new Date(new Date(item.appointmentEndTime).getTime() - 15 * 60000).getMinutes() === minute) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id;
			// 			}
			// 			else {
			// 				return (new Date(item.appointmentEndTime).getHours() === hour && new Date(item.appointmentEndTime).getMinutes() === minute + 15) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id;
			// 			}
			// 		}
			// 		else{
			// 			if (minute === 30) {
			// 				return (new Date(item.appointmentEndTime).getHours() === hour && new Date(new Date(item.appointmentEndTime).getTime() - 15 * 60000).getMinutes() === minute) && item.teammateID === parseInt(personel);
			// 			}
			// 			else {
			// 				return (new Date(item.appointmentEndTime).getHours() === hour && new Date(item.appointmentEndTime).getMinutes() === minute + 15) && item.teammateID === parseInt(personel);
			// 			}
			// 		}
			// 	}
			// }) !== undefined){
			// 	//burada sıkıntı yok çalışıyor elleme
			// 	return (
			// 		props.appointments
			// 			.find(item =>{
			// 					if (new Date(item.appointmentTime).getDate() === startDate.getDate()){
			// 						if(personel === ''){
			// 							if (minute === 30) {
			// 								return (new Date(item.appointmentTime).getHours() === hour && new Date(new Date(item.appointmentTime).getTime() + 15 * 60000).getMinutes() === minute) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id;
			// 							}
			// 							else {
			// 								return (new Date(item.appointmentTime).getHours() === hour - 1 && new Date(item.appointmentTime).getMinutes() === minute + 45) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id;
			// 							}
			// 						}
			// 						else{
			// 							if (minute === 30) {
			// 								return (new Date(item.appointmentTime).getHours() === hour && new Date(new Date(item.appointmentTime).getTime() + 15 * 60000).getMinutes() === minute) && item.teammateID === parseInt(personel);
			// 							}
			// 							else {
			// 								return (new Date(item.appointmentTime).getHours() === hour - 1 && new Date(item.appointmentTime).getMinutes() === minute + 45) && item.teammateID === parseInt(personel);
			// 							}
			// 						}
			// 					}
			// 				}
			// 			) !== undefined
			// 		&&
			// 		props.appointments
			// 			.find(item => {
			// 				if (new Date(item.appointmentTime).getDate() === startDate.getDate()) {
			// 					if(personel === '') {
			// 						if (minute === 30) {
			// 							return (new Date(item.appointmentEndTime).getHours() === hour && new Date(new Date(item.appointmentEndTime).getTime() - 15 * 60000).getMinutes() === minute) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id;
			// 						}
			// 						else {
			// 							return (new Date(item.appointmentEndTime).getHours() === hour && new Date(item.appointmentEndTime).getMinutes() === minute + 15) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id;
			// 						}
			// 					}
			// 					else{
			// 						if (minute === 30) {
			// 							return (new Date(item.appointmentEndTime).getHours() === hour && new Date(new Date(item.appointmentEndTime).getTime() - 15 * 60000).getMinutes() === minute) && item.teammateID === parseInt(personel);
			// 						}
			// 						else {
			// 							return (new Date(item.appointmentEndTime).getHours() === hour && new Date(item.appointmentEndTime).getMinutes() === minute + 15) && item.teammateID === parseInt(personel);
			// 						}
			// 					}
			// 				}
			// 			}) !== undefined
			// 	)
			// }
			// if(props.appointments.find(item =>{if (new Date(item.appointmentTime).getDate() === startDate.getDate()){
			// 	if(personel === '') {
			// 		return (new Date(item.appointmentTime).getHours() === hour && new Date(item.appointmentTime).getMinutes() === minute) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id
			// 			||
			// 			(new Date(item.appointmentEndTime).getHours() === hour && new Date(item.appointmentEndTime).getMinutes() === minute) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id
			// 	}
			// 	else{
			// 		return (new Date(item.appointmentTime).getHours() === hour && new Date(item.appointmentTime).getMinutes() === minute) && item.teammateID === parseInt(personel)
			// 			||
			// 			(new Date(item.appointmentEndTime).getHours() === hour && new Date(item.appointmentEndTime).getMinutes() === minute) && item.teammateID === parseInt(personel);
			// 	}
			// }}) !== undefined){
			// 	//burada sıkıntı yok çalışıyor elleme
			// 	return props.appointments
			// 		.find(item =>{
			// 				if (new Date(item.appointmentTime).getDate() === startDate.getDate()){
			// 					if(personel === '') {
			// 						return (new Date(item.appointmentTime).getHours() === hour && new Date(item.appointmentTime).getMinutes() === minute) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id
			// 							||
			// 							(new Date(item.appointmentEndTime).getHours() === hour && new Date(item.appointmentEndTime).getMinutes() === minute) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id
			// 					}
			// 					else{
			// 						return (new Date(item.appointmentTime).getHours() === hour && new Date(item.appointmentTime).getMinutes() === minute) && item.teammateID === parseInt(personel)
			// 							||
			// 							(new Date(item.appointmentEndTime).getHours() === hour && new Date(item.appointmentEndTime).getMinutes() === minute) && item.teammateID === parseInt(personel);
			// 					}
			// 				}
			// 			}
			// 		) !== undefined;
			// }
			// if(minute === 15 || minute === 45){
			// 	return (props.appointments
			// 		.find(item =>{
			// 			if (new Date(item.appointmentTime).getDate() === startDate.getDate()) {
			// 				if(personel === ''){
			// 					return new Date(item.appointmentTime).getHours() === hour && new Date(item.appointmentTime).getMinutes() === minute - 15 && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id;
			// 				}
			// 				else{
			// 					return new Date(item.appointmentTime).getHours() === hour && new Date(item.appointmentTime).getMinutes() === minute - 15 && item.teammateID === parseInt(personel);
			// 				}
			// 			}
			// 		}
			// 		) !== undefined && props.appointments
			// 		.find(item => {
			// 			if (new Date(item.appointmentTime).getDate() === startDate.getDate()) {
			// 				if(personel === '') {
			// 					if (minute === 45) {
			// 						return (new Date(item.appointmentEndTime).getHours() === hour + 1 && new Date(new Date(item.appointmentEndTime).getTime() - 15 * 60000).getMinutes() === minute) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id;
			// 					} else {
			// 						return (new Date(item.appointmentEndTime).getHours() === hour && new Date(item.appointmentEndTime).getMinutes() === minute + 15) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id;
			// 					}
			// 				}
			// 				else{
			// 					if (minute === 45) {
			// 						return (new Date(item.appointmentEndTime).getHours() === hour + 1 && new Date(new Date(item.appointmentEndTime).getTime() - 15 * 60000).getMinutes() === minute) && item.teammateID === parseInt(personel);
			// 					} else {
			// 						return (new Date(item.appointmentEndTime).getHours() === hour && new Date(item.appointmentEndTime).getMinutes() === minute + 15) && item.teammateID === parseInt(personel);
			// 					}
			// 				}
			// 			}
			// 		}) !== undefined)
			// }
			// return props.appointments
			// 	.find(item =>{
			// 		if (new Date(item.appointmentTime).getDate() === startDate.getDate()) {
			// 			if(personel === '') {
			// 				return (new Date(item.appointmentTime).getHours() === hour && new Date(item.appointmentTime).getMinutes() === minute) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id
			// 					||
			// 					(new Date(item.appointmentEndTime).getHours() === hour && new Date(item.appointmentEndTime).getMinutes() === minute) && item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id
			// 			}
			// 			else{
			// 				return (new Date(item.appointmentTime).getHours() === hour && new Date(item.appointmentTime).getMinutes() === minute) && item.teammateID === parseInt(personel)
			// 					||
			// 					(new Date(item.appointmentEndTime).getHours() === hour && new Date(item.appointmentEndTime).getMinutes() === minute) && item.teammateID === parseInt(personel);
			// 			}
			// 		}
			// 	}
			// 	) !== undefined;
			return appointmentsArrayForHours.find(item =>{
				if (new Date(item.startDate).getDate() === startDate.getDate()){
					if(personel === '') {
						if(item.teammateID === props.teammates.find(item => item.is_default_teammate === 1).id){
							return item.insideDates.find((insideDate) => {
								return new Date(insideDate).getHours() === hour && new Date(insideDate).getMinutes() === minute
							}) !== undefined
						}
					}
					else{
						if(item.teammateID === parseInt(personel)){
							return item.insideDates.find((insideDate) => {
								return new Date(insideDate).getHours() === hour && new Date(insideDate).getMinutes() === minute
							}) !== undefined
						}
					}
				}}) !== undefined
		}
	}
	const iframe = () => {
		return {
			__html: props.onlineAppointmentInfos.iframe
		}
	}
	function convertDateForIos(date) {
		let arr = date.split(/[- :]/);
		date = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);
		return date;
	}
	return (
		<div className="row">
			<div className="col-xl-8 col-lg-7 col-md-12">
				<div className="content-box">
					<div className="content-header">
						<h3 className="title"><i className="la la-bullhorn m-r5"></i>Online Randevu Al</h3>
					</div>
					<div className="content-body">
						{props.onlineAppointmentState === '1' 
							? <div className="clear" id="comment-list">
								<div className="comments-area" id="comments">
									<div className="comment-form" id="commentform" >
										<div className="mb-3">
											<Form.Control as="select" onChange={onChangeHizmet} custom>
												{props.services.map((service) => (
													<option value={service.id} selected={props.defaultService === service.service_name ? true : false} >{service.service_name}</option>
												))}
											</Form.Control>
										</div>
										<div className="mb-3">
											<Form.Control as="select" onChange={onChangePersonel} custom>
												{props.teammates.map((teammate) => (
													<option value={teammate.id} selected={props.defaultTeammate === teammate.teammateName ? true : false} >{teammate.teammateName}</option>
												))}
											</Form.Control>
										</div>
										<p className="comment-form text-center ml-1">
											<span style={{fontWeight: 'bold'}} className="mr-3">Tarih Seçimi</span>
											<DatePicker
												selected={startDate}
												onChange={date => {
													appointmentCalendarPrepare(date)
													setStartDate(date)
													setSaat('0')
												}}
												locale={locale}
												dateFormat="d MMMM yyyy"
												minDate={new Date()}
												maxDate={new Date(new Date().setMonth(new Date().getMonth()+3))}
												showDisabledMonthNavigation
											/>
										</p>
										<div className="mb-3">
											<InputGroup className="mb-3">
												<InputGroup.Prepend>
													<InputGroup.Text id="basic-addon1">Saat: </InputGroup.Text>
												</InputGroup.Prepend>
												<Form.Control as="select" value={saat} onChange={(item) => setSaat(item.target.value)} custom>
													<option style={{backgroundColor: 'green', color: 'white'}} defaultChecked={true} value={'0'}>Saat Seçiniz</option>
													<option style={returnDisabledHour(7,0, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(7,0, startDate)}>07:00</option>
													<option style={returnDisabledHour(7,15, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(7,15, startDate)}>07:15</option>
													<option style={returnDisabledHour(7,30, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(7,30, startDate)}>07:30</option>
													<option style={returnDisabledHour(7,45, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(7,45, startDate)}>07:45</option>
													<option style={returnDisabledHour(8,0, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(8,0, startDate)}>08:00</option>
													<option style={returnDisabledHour(8,15, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(8,15, startDate)}>08:15</option>
													<option style={returnDisabledHour(8,30, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(8,30, startDate)}>08:30</option>
													<option style={returnDisabledHour(8,45, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(8,45, startDate)}>08:45</option>
													<option style={returnDisabledHour(9,0, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(9,0, startDate)}>09:00</option>
													<option style={returnDisabledHour(9,15, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(9,15, startDate)}>09:15</option>
													<option style={returnDisabledHour(9,30, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(9,30, startDate)}>09:30</option>
													<option style={returnDisabledHour(9,45, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(9,45, startDate)}>09:45</option>
													<option style={returnDisabledHour(10,0, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(10,0, startDate)}>10:00</option>
													<option style={returnDisabledHour(10,15, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(10,15, startDate)}>10:15</option>
													<option style={returnDisabledHour(10,30, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(10,30, startDate)}>10:30</option>
													<option style={returnDisabledHour(10,45, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(10,45, startDate)}>10:45</option>
													<option style={returnDisabledHour(11,0, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(11,0, startDate)}>11:00</option>
													<option style={returnDisabledHour(11,15, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(11,15, startDate)}>11:15</option>
													<option style={returnDisabledHour(11,30, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(11,30, startDate)}>11:30</option>
													<option style={returnDisabledHour(11,45, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(11,45, startDate)}>11:45</option>
													<option style={returnDisabledHour(12,0, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(12,0, startDate)}>12:00</option>
													<option style={returnDisabledHour(12,15, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(12,15, startDate)}>12:15</option>
													<option style={returnDisabledHour(12,30, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(12,30, startDate)}>12:30</option>
													<option style={returnDisabledHour(12,45, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(12,45, startDate)}>12:45</option>
													<option style={returnDisabledHour(13,0, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(13,0, startDate)}>13:00</option>
													<option style={returnDisabledHour(13,15, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(13,15, startDate)}>13:15</option>
													<option style={returnDisabledHour(13,30, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(13,30, startDate)}>13:30</option>
													<option style={returnDisabledHour(13,45, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(13,45, startDate)}>13:45</option>
													<option style={returnDisabledHour(14,0, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(14,0, startDate)}>14:00</option>
													<option style={returnDisabledHour(14,15, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(14,15, startDate)}>14:15</option>
													<option style={returnDisabledHour(14,30, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(14,30, startDate)}>14:30</option>
													<option style={returnDisabledHour(14,45, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(14,45, startDate)}>14:45</option>
													<option style={returnDisabledHour(15,0, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(15,0, startDate)}>15:00</option>
													<option style={returnDisabledHour(15,15, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(15,15, startDate)}>15:15</option>
													<option style={returnDisabledHour(15,30, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(15,30, startDate)}>15:30</option>
													<option style={returnDisabledHour(15,45, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(15,45, startDate)}>15:45</option>
													<option style={returnDisabledHour(16,0, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(16,0, startDate)}>16:00</option>
													<option style={returnDisabledHour(16,15, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(16,15, startDate)}>16:15</option>
													<option style={returnDisabledHour(16,30, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(16,30, startDate)}>16:30</option>
													<option style={returnDisabledHour(16,45, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(16,45, startDate)}>16:45</option>
													<option style={returnDisabledHour(17,0, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(17,0, startDate)}>17:00</option>
													<option style={returnDisabledHour(17,15, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(17,15, startDate)}>17:15</option>
													<option style={returnDisabledHour(17,30, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(17,30, startDate)}>17:30</option>
													<option style={returnDisabledHour(17,45, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(17,45, startDate)}>17:45</option>
													<option style={returnDisabledHour(18,0, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(18,0, startDate)}>18:00</option>
													<option style={returnDisabledHour(18,15, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(18,15, startDate)}>18:15</option>
													<option style={returnDisabledHour(18,30, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(18,30, startDate)}>18:30</option>
													<option style={returnDisabledHour(18,45, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(18,45, startDate)}>18:45</option>
													<option style={returnDisabledHour(19,0, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(19,0, startDate)}>19:00</option>
													<option style={returnDisabledHour(19,15, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(19,15, startDate)}>19:15</option>
													<option style={returnDisabledHour(19,30, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(19,30, startDate)}>19:30</option>
													<option style={returnDisabledHour(19,45, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(19,45, startDate)}>19:45</option>
													<option style={returnDisabledHour(20,0, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(20,0, startDate)}>20:00</option>
													<option style={returnDisabledHour(20,15, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(20,15, startDate)}>20:15</option>
													<option style={returnDisabledHour(20,30, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(20,30, startDate)}>20:30</option>
													<option style={returnDisabledHour(20,45, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(20,45, startDate)}>20:45</option>
													s<option style={returnDisabledHour(21,0, startDate) === true ? {backgroundColor: 'red', color: 'white'} : {backgroundColor: 'green', color: 'white'}} disabled={returnDisabledHour(21,0, startDate)}>21:00</option>
												</Form.Control>
											</InputGroup>
										</div>
										<div className="mb-3">
											<InputGroup className="mb-3">
												<InputGroup.Prepend>
													{/*<InputGroup.Text id="basic-addon1">Telefon</InputGroup.Text>*/}
													<PhoneInput
														country={'tr'}
														enableSearch={true}
														countryCodeEditable={false}
														onChange={phone => {
															if(phone.length === 12){
																props.controlPhone(true, phone)
																setPhone(phone)
															}else{
																props.controlPhone(true, '0')
																setPhone('0')
															}
														}}
														inputStyle={{
															height: '40px'
														}}
													/>
												</InputGroup.Prepend>
											</InputGroup>
										</div>
										<div style={props.customerFullName === '0' ? {display: "none"} : {display: "block"}} className="mb-3">
											<InputGroup className="mb-3">
												<InputGroup.Prepend>
													<InputGroup.Text id="basic-addon1">Ad Soyad: </InputGroup.Text>
												</InputGroup.Prepend>
												<FormControl
													placeholder="Ad Soyad Yazınız"
													value={props.customerFullName === '1' ? kayitsizCustomerFullName : props.customerFullName}
													disabled={props.customerFullName !== '1'}
													onChange={(item) => setKayitsizCustomerFullName(item.target.value)}
												/>
											</InputGroup>
										</div>
										<div className="mb-3">
											<InputGroup className="mb-3">
												<InputGroup.Prepend>
													<InputGroup.Text id="basic-addon1">Not: </InputGroup.Text>
												</InputGroup.Prepend>
												<FormControl
													placeholder="Notunuzu Yazınız (Opsiyonel)"
													onChange={item => setNot(item.target.value)}
												/>
											</InputGroup>
										</div>
										<p className="form-submit">
											<button value="Randevu Al" disabled={disableButton} style={{width: '100%', height: '50px', backgroundColor: '#4611a7', color: 'white', borderRadius: '25px'}} 
													onClick={() => {
														let mmStart = startDate.getMonth() + 1;
														let ddStart = startDate.getDate();
														let yyStart = startDate.getFullYear();
														let appointmentStartDate = yyStart + '-' + mmStart + '-' + ddStart + ' ' + saat
														if(saat === 'Saat Seçiniz' || saat === '0'){
															alert('Lütfen saat seçimi yapınız!')
														}
														else{
															if(phone === '0'){
																alert('Lütfen telefon numarası giriniz!')
															}
															else{
																if((props.customerFullName !== '1' && kayitsizCustomerFullName === '') || kayitsizCustomerFullName !== ''){
																	setDisableButton(1)
																	axios.post('https://api.saderandevu.com/api/appointments/add-online-appointment', {
																		businessId: props.businessId,
																		personel: personel === '' ? props.defaultTeammate : parseInt(personel),
																		service: service === '' ? props.defaultService : parseInt(service),
																		phone: phone,
																		customerName: props.customerFullName === '1' ? kayitsizCustomerFullName : props.customerFullName,
																		appointmentStartDate: appointmentStartDate,
																		not: not
																	}).then((response) => {
																		// console.log('respone: ', response.data)
																		// alert('Start: ' + response.data.data["startDate"])
																		// alert('End: ' + response.data.data["endDate"])
																		setDisableButton(0)
																		setSaat('0')
																		alert('Başarıyla randevu aldınız. Lütfen işletmeden gelebilecek olası mesajlar için telefonunuzu kontrol ediniz!')
																	}).catch(err => console.log(err.response.request))
																} 
																else if((props.customerFullName === '1' && kayitsizCustomerFullName === '') || kayitsizCustomerFullName === ''){
																	alert('Lütfen ad soyad giriniz!')
																}
															}
														}
													}}>Randevu Al</button>
										</p>
									</div>
								</div>
							</div> 
							: <h2>Bu işletmenin Online Randevu Alma özelliği kapalı. Lütfen iletişim bilgilerinden kendisine ulaşın!</h2>
						}
					</div>
				</div>
				<div className="content-box">
					<div className="content-header">
						<h3 className="title"><i className="la la-file-text m-r5"></i> Çalışma Saatleri</h3>
					</div>
					<div className="content-body collapse show" id="collapseOne" aria-labelledby="headingOne" data-parent="#accordion">
						<ul className="listing-time-list">
							<li><span className="listing-day">Pazartesi</span> <span className="listing-hours">{props.onlineAppointmentInfos.mondayStart} - {props.onlineAppointmentInfos.mondayEnd}</span></li>
							<li><span className="listing-day">Salı</span> <span className="listing-hours">{props.onlineAppointmentInfos.tuesdayStart} - {props.onlineAppointmentInfos.tuesdayEnd}</span></li>
							<li><span className="listing-day">Çarşamba</span> <span className="listing-hours">{props.onlineAppointmentInfos.wednesdayStart} - {props.onlineAppointmentInfos.wednesdayEnd}</span></li>
							<li><span className="listing-day">Perşembe</span> <span className="listing-hours">{props.onlineAppointmentInfos.thursdayStart} - {props.onlineAppointmentInfos.thursdayEnd}</span></li>
							<li><span className="listing-day">Cuma</span> <span className="listing-hours">{props.onlineAppointmentInfos.fridayStart} - {props.onlineAppointmentInfos.fridayEnd}</span></li>
							<li><span className="listing-day">Cumartesi</span> <span className="listing-hours">{props.onlineAppointmentInfos.saturdayStart} - {props.onlineAppointmentInfos.saturdayEnd}</span></li>
							<li><span className="listing-day">Pazar</span> <span className="listing-hours">{props.onlineAppointmentInfos.sundayStart} - {props.onlineAppointmentInfos.sundayEnd}</span></li>
						</ul>
					</div>
				</div>
			</div>
			<div className="col-xl-4 col-lg-5 col-md-12"> 
				{/*kaymasını istiyorsan üste sticky-top classı ekle*/}
				<aside className="side-bar listing-side-bar">
					<div className="content-box">
						<div className="content-header">
							<h3 className="title"><i className="la la-file-text m-r5"></i> İşletmeye Ulaşın</h3>
						</div>
						<div className="content-body">
							<ul className="icon-box-list">
								<li><a style={{color: '#555'}} target="_blank" href={props.onlineAppointmentInfos.gmbAddress}>
									<div className="icon-cell bg-gray">
										<i className="la la-map-marker"></i>
									</div>
									<span>{props.onlineAppointmentInfos.address}</span>
								</a>
								</li>
								<li><a style={{color: '#555'}} href={"tel:" + props.onlineAppointmentInfos.phone}>
									<div className="icon-cell bg-gray">
										<i className="la la-phone"></i>
									</div>
									<span>{props.onlineAppointmentInfos.phone}</span>
								</a></li>
								<li><a style={{color: '#555'}} target="_blank" href={props.onlineAppointmentInfos.webSite}>
									<div className="icon-cell bg-gray">
										<i className="la la-globe"></i>
									</div>
									<span>{props.onlineAppointmentInfos.webSite}</span>
								</a></li>
								<li><a style={{color: '#555'}} href={"mailto:" + props.onlineAppointmentInfos.email}>
									<div className="icon-cell bg-gray">
										<i className="la la-envelope"></i>
									</div>
									<span>{props.onlineAppointmentInfos.email}</span>
								</a></li>
							</ul>
						</div>
					</div>
					<div className="content-box">
						<div className="content-header">
							<h3 className="title"><i className="la la-map-marker m-r5"></i>Harita</h3>
						</div>
						<div className="content-body">
							<div  className="align-self-stretch" >
								<div dangerouslySetInnerHTML={iframe()} />
							</div>
						</div>
					</div>
				</aside>
			</div>
		</div>
	)
} 

export default AppointmentTabContent;