import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import OnlineAppointmentPage from './Pages/OnlineAppointmentPage';
import ScrollToTop from './Element/ScrollToTop';

class Markup extends Component {
    render() {
        return (
            <BrowserRouter basename="/">
                <div className="page-wraper">
                    <Switch>
                        <Route path='/' exact component={HomePage} />
						<Route path='/:name' exact component={OnlineAppointmentPage} />
                    </Switch>
                </div>
                <ScrollToTop />
            </BrowserRouter>
        )
    }
}

export default Markup;