import React from 'react';
import AppointmentTabContent from './../Element/AppointmentTabContent';

const AppointmentTab = (props) => {
	return(
		<>
			<div className="section-full listing-details-content" style={{marginTop: '-25px'}}>
				<div className="container">	
					<div className="tab-content">
						<AppointmentTabContent 
							onlineAppointmentState={props.onlineAppointmentState}
							onlineAppointmentInfos={props.onlineAppointmentInfos}
							services={props.services}
							businessId={props.businessId}
							teammates={props.teammates} 
							defaultService={props.defaultService} 
							defaultTeammate={props.defaultTeammate} 
							islemSuresi={props.islemSuresi} 
							appointments={props.appointments}
							controlPhone={(controlPhone, phoneNumber) => controlPhone && props.controlPhone(true, phoneNumber)}
							customerFullName={props.customerName}
						/>
					</div>
				</div>
			</div>
		</>
	)
}


export default AppointmentTab;