import React, {Component} from 'react';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
let bnr = require('./../../images/banner/bnr3.jpg');
class HomePage extends Component{
	render(){
		return(
			<div className="page-wraper">
				<Header />
				<div className=" bg-white">
					<div className="dlab-bnr-inr dlab-bnr-inr-sm overlay-black-middle" style={{backgroundImage:"url(" + bnr + ")" }}>
						<div className="container">
							<div className="dlab-bnr-inr-entry">
								<h1 className="text-white">Şimdi Online Randevu Sisteminizi Yönetin</h1>
								<br/>
								<p>SadeRandevu aracılığıyla sizde işletmenize online randevu özelliğini getirebilirsiniz.</p>
								<p>Hemen SadeRandevu panelinize giriş yapın ve online randevu özelliğinizi etkinleştirin.</p>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		)	
	}
}

export default HomePage;